import { Controller } from "stimulus"
import { get as getRequest } from '@rails/request.js'
import { get } from 'lodash'
import jquery from "jquery"

const getCompanyPersonRole = async personId => await getRequest(
  `/company_person_roles.json?q[person_learner_id_eq=${personId}]`
).then(
  response => response.json
).then(
  json => get(json, "0", {})
)

const getCustomer = async companyId => await getRequest(
  `/customers.json?q[company_id_eq=${companyId}]`
).then(
  response => response.json
).then(
  json => get(json, "0", {})
)

export default class extends Controller {
  static targets = [ "learnerId", "workJob", "customerId" ]

  learnerChanged = async evt => {
    const company_person_role = await getCompanyPersonRole(evt.target.value)

    this.workJobTarget.value = company_person_role.data?.description || ""

    const companyId =  company_person_role.company_id
    if(companyId) {
      const customer = await getCustomer(companyId)
      const newOption = new Option(customer.name, customer.id,  true, true)
      $(this.customerIdTarget).append(newOption).trigger('change')
    } else {
      this.customerIdTarget.options[1]?.remove()
    }
  }
}
