import { Controller } from "stimulus"

const toast = (clazz, message) => {
  const element = document.getElementsByClassName('c-alert--' + clazz)[0];
  element.textContent = message || clazz
  element.style.display = 'block'
  setTimeout(function() {
    element.style.display = 'none'
    element.textContent = clazz
  }, 3000);
}

export default class extends Controller {
  static targets = [ 'button' ]

  connect() {
  }


  toggle(evt) {
    evt.preventDefault()
    console.debug(evt)

    const button = this.buttonTarget
    const { url,  actAs } = button.dataset
    const data = { [`${actAs}_attributes`]: {} }
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json', 
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then( result => {
      if(result.ok) {
        button.classList.toggle('toggle-on')
        button.classList.toggle('toggle-off')
        delete button.dataset.id
        toast('success', 'Ruolo modificato')
      } else {
        // console.error(result)
        toast('error', 'Non è possibile modificare il ruolo!')
      }
    })
    .catch( error => {
      console.error(error)
     })
  }
}
